import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { object, array } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import { v4 as uuidv4 } from 'uuid'

import { DISPLAY_WIDTH } from '../util/constants'
import * as Styled from './styles/AdvanceLanding.styles'
import { RegionContainer } from '../components/styles/Utility.styles'
import RecentAdvanceArticles from '../components/ContentBlocks/RecentAdvanceArticles'
import AdvanceArticleGridItem from '../components/ContentBlocks/AdvanceArticleGridItem'
import CtaLink from '../components/ContentBlocks/CtaLink'
import PaginationControl from '../components/PaginatonControl'
import LinkWrapper from './ContentBlocks/LinkWrapper'
import {
  getStorageString,
  setStorageString,
  removeStorageString,
  StorageKey,
  StorageType,
} from '../util/storage'
import WithLocation from '../util/WithLocation'
import AdSlot from './AdSlot'

const ITEMS_PER_PAGE = 23

const ARTICLE_QUERY = graphql`
  {
    allPrismicAdvanceArticle(
      sort: { fields: data___publish_date, order: DESC }
    ) {
      edges {
        node {
          uid
          id
          type
          data {
            add_last_updated_date_label
            publish_date
            header {
              text
              richText
            }
            intro_text {
              text
              richText
            }
            hero_image {
              alt
              url
              thumbnails {
                mobile {
                  url
                }
                landing_page_featured {
                  url
                }
                landing_page_grid {
                  url
                }
              }
            }
            article_type {
              document {
                ... on PrismicAdvanceArticleType {
                  id
                  data {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const FeaturedArticle = ({ article, topLineAdSlot }) => (
  <Styled.FeaturedContentRegion>
    <Styled.FeaturedArticleImageContainer>
      <LinkWrapper link={article}>
        <picture>
          <source
            media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
            srcSet={article.data.hero_image.thumbnails.mobile.url}
          />
          <source
            media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
            srcSet={
              article.data.hero_image.thumbnails.landing_page_featured.url
            }
          />
          <img
            src={article.data.hero_image.url}
            alt={article.data.hero_image.alt}
          />
        </picture>
      </LinkWrapper>
    </Styled.FeaturedArticleImageContainer>
    <Styled.FeaturedArticleTitle>
      <PrismicRichText field={article.data.header.richText} />
      {/* {article.header?.slice(0, 1).pop().text ?? ''} */}
    </Styled.FeaturedArticleTitle>
    <Styled.FeaturedArticleBodyText>
      <PrismicRichText field={article.data.intro_text.richText} />
    </Styled.FeaturedArticleBodyText>
    <CtaLink text="Read article" link={article} />
    {topLineAdSlot && (
      <Styled.TopLineGoogleAdContainer>
        <AdSlot divId={topLineAdSlot} />
      </Styled.TopLineGoogleAdContainer>
    )}
  </Styled.FeaturedContentRegion>
)

const FilterControlRow = ({
  types,
  articleTypeFilter,
  setArticleTypeFilter,
}) => (
  <Styled.FilterControlRow>
    <Styled.FilterControlButtonDesktop
      onClick={() => setArticleTypeFilter(null)}
      isActive={articleTypeFilter === null}
    >
      All
    </Styled.FilterControlButtonDesktop>
    {types.map(type => (
      <Styled.FilterControlButtonDesktop
        key={type.node.id}
        onClick={() => setArticleTypeFilter(type.node.data.type)}
        isActive={articleTypeFilter === type.node.data.type}
      >
        {type.node.data.type}
      </Styled.FilterControlButtonDesktop>
    ))}
  </Styled.FilterControlRow>
)

const ToggleArrow = ({ isOpen, toggle }) => (
  <svg
    onClick={() => toggle()}
    width="32"
    height="32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {!isOpen ? (
      <path
        fill="#F2F6F8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm0 2C7.1634 32 0 24.8366 0 16S7.1634 0 16 0s16 7.1634 16 16-7.1634 16-16 16zm4.2667-16.5577c.3293-.3623.8991-.3621 1.228.0006a.8292.8292 0 010 1.1142l-5.1028 5.6262c-.3969.4376-1.0846.4376-1.4815 0l-5.1028-5.6262a.8292.8292 0 010-1.1142c.3289-.3627.8988-.3629 1.228-.0006l3.822 4.2064V9.7936a.7936.7936 0 111.5872 0v9.8551l3.8219-4.2064z"
      />
    ) : (
      <path
        fill="#F2F6F8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16c0 8.8366 7.1634 16 16 16s16-7.1634 16-16S24.8366 0 16 0 0 7.1634 0 16zm20.2667-.5577c.3293-.3623.8991-.3621 1.228.0006a.8292.8292 0 010 1.1142l-5.1028 5.6262c-.3969.4376-1.0846.4376-1.4815 0l-5.1028-5.6262a.8292.8292 0 010-1.1142c.3289-.3627.8988-.3629 1.228-.0006l3.822 4.2064V9.7936a.7936.7936 0 111.5872 0v9.8551l3.8219-4.2064z"
      />
    )}
  </svg>
)

const FilterControlColumn = ({
  types,
  articleTypeFilter,
  setArticleTypeFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Styled.FilterControlColumn isOpen={isOpen}>
      <Styled.FilterColumnButtonRow>
        <Styled.FilterControlButtonMobile
          style={{ height: '60px' }}
          onClick={() => {
            if (isOpen) {
              setIsOpen(false)
            }
          }}
        >
          {!articleTypeFilter ? 'All' : articleTypeFilter}
        </Styled.FilterControlButtonMobile>
        <ToggleArrow isOpen={isOpen} toggle={toggle} />
      </Styled.FilterColumnButtonRow>
      {articleTypeFilter && (
        <Styled.FilterColumnButtonRow>
          <Styled.FilterControlButtonMobile
            onClick={() => {
              setIsOpen(false)
              setArticleTypeFilter(null)
            }}
          >
            All
          </Styled.FilterControlButtonMobile>
        </Styled.FilterColumnButtonRow>
      )}
      {types.map(type => (
        <Styled.FilterColumnButtonRow key={type.node.id}>
          <Styled.FilterControlButtonMobile
            onClick={() => {
              setIsOpen(false)
              setArticleTypeFilter(type.node.data.type)
            }}
            isActive={articleTypeFilter === type.node.data.type}
          >
            {type.node.data.type}
          </Styled.FilterControlButtonMobile>
        </Styled.FilterColumnButtonRow>
      ))}
    </Styled.FilterControlColumn>
  )
}

const AdvanceArticleGrid = ({
  pages,
  articleTypeFilter,
  articleGridAdSlot,
  firstItemForPage,
  lastItemForPage,
  setFilteredItemsCount,
}) => {
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    setFiltered(
      pages.filter(item => {
        if (!articleTypeFilter) {
          return true
        }
        if (!item.node.data.article_type?.document?.data?.type) {
          return false
        }
        return articleTypeFilter
          ? item.node.data.article_type.document.data.type === articleTypeFilter
          : true
      })
    )
  }, [articleTypeFilter, pages])

  useEffect(() => {
    setFilteredItemsCount(filtered.length)
  }, [filtered])

  return (
    <Styled.ArticleGrid>
      {filtered.slice(firstItemForPage, lastItemForPage).map((item, index) =>
        index !== 3 ? (
          <AdvanceArticleGridItem key={item.node.id} item={item.node} />
        ) : (
          <React.Fragment key={`ad-wrapper-${item.node.id}`}>
            <Styled.ArticleGridGoogleAdContainer>
              <AdSlot divId={articleGridAdSlot} />
            </Styled.ArticleGridGoogleAdContainer>
            <AdvanceArticleGridItem key={item.node.id} item={item.node} />
          </React.Fragment>
        )
      )}
    </Styled.ArticleGrid>
  )
}

const AdvanceLanding = ({ doc, types, paginationChangeAction, search }) => {
  const [articleTypeFilter, setArticleTypeFilter] = useState(null)
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)
  const [filteredItemsCount, setFilteredItemsCount] = useState(null)
  const hasPreviousFilter = getStorageString({
    key: StorageKey.ADVANCE_ARTICLE_FILTER,
    storageType: StorageType.SESSION,
  })
  useEffect(() => {
    if (hasPreviousFilter) {
      setArticleTypeFilter(hasPreviousFilter)
    }
  }, [getStorageString])

  useEffect(() => {
    if (search.topic) {
      setArticleTypeFilter(search.topic)
    }
  }, [search])

  useEffect(() => {
    if (!articleTypeFilter) {
      if (hasPreviousFilter) {
        removeStorageString({
          key: StorageKey.ADVANCE_ARTICLE_FILTER,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.ADVANCE_ARTICLE_FILTER,
      value: articleTypeFilter,
      storageType: StorageType.SESSION,
    })
  }, [articleTypeFilter])

  return (
    <StaticQuery
      query={`${ARTICLE_QUERY}`}
      render={data => {
        const pages = data.allPrismicAdvanceArticle.edges
        return (
          <>
            <RegionContainer>
              <Styled.UpperContentRegion>
                <FeaturedArticle
                  article={doc.featured_post.document}
                  topLineAdSlot={`div-${doc.top_line_google_ad?.document?.data?.ad_slot_name}`}
                />
                <Styled.RecentArticleColumn>
                  <Styled.SidebarGoogleAdOneContainer>
                    <AdSlot
                      divId={`div-${doc.sidebar_google_ad_one?.document?.data?.ad_slot_name}`}
                    />
                  </Styled.SidebarGoogleAdOneContainer>
                  <RecentAdvanceArticles />
                </Styled.RecentArticleColumn>
              </Styled.UpperContentRegion>
            </RegionContainer>

            <RegionContainer>
              <Styled.FilterControlRegion>
                <Styled.ArticleFilterSectionTitle>
                  Filter Articles
                </Styled.ArticleFilterSectionTitle>
                <FilterControlColumn // Only visible on Mobile
                  types={types}
                  articleTypeFilter={articleTypeFilter}
                  setArticleTypeFilter={setArticleTypeFilter}
                />
                <FilterControlRow // Only visible above Mobile
                  types={types}
                  articleTypeFilter={articleTypeFilter}
                  setArticleTypeFilter={setArticleTypeFilter}
                />
              </Styled.FilterControlRegion>
            </RegionContainer>

            <Styled.ArticleAreaGreyFill>
              <Styled.GridAndPaginationContainer>
                <AdvanceArticleGrid
                  pages={pages}
                  articleTypeFilter={articleTypeFilter}
                  articleGridAdSlot={`div-${doc.article_grid_google_ad?.document?.data?.ad_slot_name}`}
                  firstItemForPage={firstItemForPage}
                  lastItemForPage={lastItemForPage}
                  setFilteredItemsCount={setFilteredItemsCount}
                />
                <PaginationControl
                  itemCount={
                    filteredItemsCount ? filteredItemsCount : pages.length
                  }
                  itemsPerPage={ITEMS_PER_PAGE}
                  setFirstItemForPage={setFirstItemForPage}
                  setLastItemForPage={setLastItemForPage}
                  paginationChangeAction={paginationChangeAction}
                />
              </Styled.GridAndPaginationContainer>
              <Styled.BottomLineGoogleAdContainer>
                <AdSlot
                  divId={`div-${doc.bottom_line_google_ad?.document?.data?.ad_slot_name}`}
                />
              </Styled.BottomLineGoogleAdContainer>
            </Styled.ArticleAreaGreyFill>
          </>
        )
      }}
    />
  )
}

AdvanceLanding.propTypes = {
  doc: object,
  types: array,
  pages: array,
}

export default WithLocation(AdvanceLanding)
