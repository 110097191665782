import React, { useEffect, useState } from 'react'
import * as Styled from './styles/AdSlot.styles'

const AdSlot = ({ divId }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    if (!isBrowser) return

    const MAX_WAIT_TIME = 10000
    const startTime = Date.now()

    const displayInterval = setInterval(() => {
      if (Date.now() - startTime > MAX_WAIT_TIME) {
        // console.warn(`ADTEST: Timeout waiting for slot ${divId}`)
        clearInterval(displayInterval)
        return
      }

      if (window.gptInitialized && document.getElementById(divId)) {
        window.googletag.cmd.push(() => {
          try {
            // console.log(`ADTEST: Displaying ad for ${divId}`)
            window.googletag.display(divId)
          } catch (error) {
            console.error(`Error displaying ad for ${divId}:`, error)
          }
        })
        clearInterval(displayInterval)
      }
    }, 50)

    return () => clearInterval(displayInterval)
  }, [divId, isBrowser])

  return <Styled.AdSlot id={divId} />
}

export default AdSlot
